import { Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { isNil } from "lodash";
import React from "react";
import dayjs, { Dayjs } from "dayjs";

import CultivationAPI from "../api/Cultivation.api";
import HoeDateAPI from "../api/HoeDate.api";
import IrrigationAPI from "../api/Irrigation.api";
import MaterialApplicationAPI from "../api/MaterialApplication.api";
import SprayAPI from "../api/Spray.api";
import {
  Cultivation,
  Harvest,
  HoeDate,
  Irrigation,
  MaterialApplication,
  Spray,
} from "../types";
import {
  extractDate,
  irrigationMethodToString,
  materialApplicationDataToString,
  materialApplicationPhaseToString,
} from "../utils";
import HarvestAPI from "../api/Harvest.api"
import MaterialsUsedList from "./MaterialsUsedList";
import IrrigationListItemInfo from './IrrigationListItemInfo'
import SprayListItemInfo from './SprayListItemInfo'

type Props = {
  harvest: Harvest;
};
export default function HarvestSummaryTab({ harvest }: Props) {
  const theme = useTheme();
  const { data: nitrogenUsedResponse } = HarvestAPI.useNitrogenUsed(harvest.id)
  const { data: cultivations } = CultivationAPI.useList(harvest.id);
  const { data: hoeDates } = HoeDateAPI.useList(harvest.id);
  const { data: irrigations } = IrrigationAPI.useList({
    irrigableType: "Harvest",
    irrigableId: harvest.id,
  });
  const { data: materialApplications } =
    MaterialApplicationAPI.useListByHarvest(harvest.id);
  const { data: sprays } = SprayAPI.useList({
    sprayableType: "Harvest",
    sprayableId: harvest.id,
  });

  const dateData = orderHarvestDateInformation({
    harvest,
    hoeDates,
    cultivations,
    irrigations,
    materialApplications,
    sprays,
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <Typography variant="h6">Nitrogen Used: {nitrogenUsedResponse?.nitrogenUsed?.toLocaleString()} lbs.</Typography>
      </Box>
      <Grid container spacing={2}>
        {dateData.map((data, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Box
              sx={{
                height: "100%",
                flex: 1,
                p: "1rem",
                display: "flex",
                flexDirection: "column",
                borderRadius: 1,
                border: `2px solid ${theme.palette.grey["300"]}`,
              }}
            >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
                <SummaryItem
                  date={data.date}
                  name={data.name}
                  subinfo={data.subinfo}
                  modelType={data.type}
                  model={data.model}
                />
              </Box>
            </Box>
          </Grid>
        ))}

      </Grid>
    </Box>
  );
}

function SummaryItem({ date, name, subinfo, modelType, model }: {
  date?: Date;
  name: string;
  subinfo?: string;
  modelType?: string;
  model?: Harvest | Cultivation | HoeDate | Irrigation | MaterialApplication | Spray;
}){
  if(modelType === 'irrigation' && model){
    const irrigation = model as Irrigation;
    return (
      <React.Fragment>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IrrigationListItemInfo irrigation={irrigation} />
        </Box>
        <Box>
          <MaterialsUsedList
            materialApplicableId={irrigation.id}
            materialApplicableType={"Irrigation"}
            phase={irrigation.preirrigation ? "preirrigating" : "irrigating"}
          />
        </Box>
      </React.Fragment>
    )
  } else if(modelType === 'cultivation'){
    const cultivation = model as Cultivation;
    return (
      <React.Fragment>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="body1">
            Cultivated on {dayjs(cultivation.appliedOn).format("MM/DD/YYYY")}
          </Typography>
        </Box>
        <Box>
          <MaterialsUsedList
            materialApplicableId={cultivation.id}
            materialApplicableType={"Cultivation"}
            phase={"cultivating"}
          />
        </Box>
      </React.Fragment>
    )
  } else if(modelType === 'spray'){
    const spray = model as Spray;
    return (
      <React.Fragment>
         <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SprayListItemInfo spray={spray} />
        </Box>
        <Box>
          <MaterialsUsedList
            materialApplicableId={spray.id}
            materialApplicableType={"Spray"}
            phase={"spraying"}
          />
        </Box>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <Typography variant="body1">{name}</Typography>
      {subinfo && (
        <Typography variant="body2">Phase {subinfo}</Typography>
      )}
      <Typography variant="body2">
        {date ? extractDate(date) : "Missing date"}
      </Typography>
    </React.Fragment>
  )
}

function orderHarvestDateInformation({
  harvest,
  cultivations,
  hoeDates,
  irrigations,
  materialApplications,
  sprays,
}: {
  harvest: Harvest;
  cultivations?: Cultivation[];
  hoeDates?: HoeDate[];
  irrigations?: Irrigation[];
  materialApplications?: MaterialApplication[];
  sprays?: Spray[];
}) {
  const data: Array<{ date?: Date; name: string; subinfo?: string, type?: string, model?: Harvest | Cultivation | HoeDate | Irrigation | MaterialApplication | Spray }> = [];
  data.push({
    date: harvest.actualWetDate,
    name: "Actual Wet Date",
  });
  data.push({
    date: harvest.estHarvestDate,
    name: "Estimated Harvest Date",
  });
  // data.push({
  //   date: harvest.listingDate,
  //   name: "Listing Date",
  // });
  data.push({
    date: harvest.projectedWetDate,
    name: "Projected Wet Date",
  });
  data.push({
    date: harvest.thinningDate,
    name: "Thinning Date",
  });
  data.push({
    date: harvest.transplantDate,
    name: "Transplant Date",
  });
  // data.push({
  //   date: harvest.hoeingDate,
  //   name: "Hoeing Date",
  // })
  // harvest?.hoeDates.sort((a,b) => {
  //   if (isNil(a.date) || isNil(b.date)) {
  //     return 0;
  //   }
  //   return b.date.getTime() - a.date.getTime();
  // }).forEach((hoeDate, i) => {
  //   data.push({
  //     date: hoeDate.date,
  //     name: `Hoe Date ${i + 1}`
  //   })
  // })
  if (hoeDates) {
    hoeDates.forEach((hoeDate) => {
      data.push({
        name: "Hoe Date",
        date: hoeDate.date,
        type: 'hoeDate',
        model: hoeDate
      });
    });
  }
  // harvest -> cultivations.applied_on
  if (cultivations) {
    cultivations.forEach((cultivation) => {
      data.push({
        name: "Cultivation",
        date: cultivation.appliedOn,
        type: 'cultivation',
        model: cultivation
      });
    });
  }
  // harvest -> irrigations.applied_on
  if (irrigations) {
    irrigations.forEach((irrigation) => {
      data.push({
        name: "Irrigation",
        date: irrigation.appliedOn,
        type: 'irrigation',
        model: irrigation
      });
    });
  }
  // harvest -> sprays.applied_on
  if (sprays) {
    sprays.forEach((spray) => {
      data.push({
        name: "Spray",
        date: spray.appliedOn,
        type: 'spray',
        model: spray
      });
    });
  }
  // harvest -> material_applications.applied_on
  if (materialApplications) {
    materialApplications.forEach((materialApplication) => {
      data.push({
        name: materialApplicationDataToString(materialApplication),
        subinfo: materialApplicationPhaseToString(materialApplication.phase),
        date: materialApplication.appliedOn,
        type: 'harvest_material_applications'
      });
    });
  }
  return data.sort((a, b) => {
    if (isNil(a.date) && isNil(b.date)) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }
    if (isNil(a.date)) {
      return 1;
    }
    if (isNil(b.date)) {
      return -1;
    }
    return b.date.getTime() - a.date.getTime();
  });
}
