import React from 'react'
import { Typography } from '@mui/material'
import dayjs from "dayjs";
import { irrigationMethodToString } from "../utils";
import { Irrigation } from '../types'

export default function IrrigationListItemInfo({ irrigation }: { irrigation: Irrigation }){
  return (
    <React.Fragment>
      <Typography variant="body1">
        {irrigationMethodToString(irrigation.method)} applied for{" "}
        {irrigation.numHours} hours.
      </Typography>
      <Typography variant="subtitle2">
        {dayjs(irrigation.appliedOn).format("MM/DD/YYYY")}
      </Typography>
    </React.Fragment>
  )
}