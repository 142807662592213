import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

import LotAPI from "../api/Lot.api";
import ReportAPI from "../api/Report.api";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import RanchSearch from "../components/RanchSearch";
import {
  Ranch
} from "../types";
import LotCropRotation from '../components/cropRotation/LotCropRotation'

const cropWidth = 10;

export default function ReportCropRotationPage() {
  const [numYearsBack, setNumYearsBack] = React.useState<number>(1);
  const [ranch, setRanch] = React.useState<Ranch | undefined>();

  const { data: cropRotationMeta } = ReportAPI.useCropRotationMeta({
    numYearsBack,
  });

  console.log('cropRotationMeta', cropRotationMeta)

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
          ml: "auto",
          mr: "auto",
          minWidth: "85vw",
          maxWidth: "85vw",
        }}
      >
        <Typography variant="h2">Crop Rotation</Typography>

        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Number of years back"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={numYearsBack}
              onChange={(e) => setNumYearsBack(parseFloat(e.target.value))}
              fullWidth
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <RanchSearch ranch={ranch} setRanch={setRanch} />
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
          {cropRotationMeta ? (
            <LotDataInfinite
              numYearsBack={numYearsBack}
              ranch={ranch}
              cropRotationMeta={cropRotationMeta}
            />
          ) : (
            <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
              <Loading />
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

export function LotDataInfinite({
  cropRotationMeta,
  numYearsBack,
  ranch,
}: {
  cropRotationMeta: { [years: number]: number };
  numYearsBack: number;
  ranch?: Ranch;
}) {
  // Order by Ranch name
  // filter by Ranch
  // leverage useInfiniteQuery for fetching all the lots
  const [page, setPage] = React.useState<number>(0);
  const { data, isLoading } = LotAPI.useListCropRotation({
    page,
    ranchId: ranch ? ranch.id : undefined,
  });
  // const meta = data?.meta;
  const currentDate = new Date();

  if (isLoading || !data?.data) {
    return (
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Loading />
      </Box>
    );
  }

  const lots = data && data.data;
  data.data.sort((lotA, lotB) => {
    const nameRanchA = lotA.ranch.name.toUpperCase();
    const nameRanchB = lotB.ranch.name.toUpperCase();
    if (nameRanchA < nameRanchB) {
      return -1;
    }
    if (nameRanchA > nameRanchB) {
      return 1;
    }
    // isNaN(lotA.num)
    if (parseInt(lotA.num) < parseInt(lotB.num)) {
      return -1;
    }
    if (parseInt(lotA.num) > parseInt(lotB.num)) {
      return 1;
    }
    return 0;
  });

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          padding: "0.3rem",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              alignSelf: "flex-end",
              minHeight: "4.55rem",
              borderBottom: `1px solid ${grey[500]}`,
            }}
          >
            <Box sx={{ minWidth: "7rem" }}>
              <Typography variant="h6" align="center">
                Ranch / Lot
              </Typography>
            </Box>
            <Box sx={{ minWidth: "7rem" }}>
              <Typography variant="h6" align="center">
                # of acres
              </Typography>
            </Box>
          </Box>

          {lots &&
            lots.map((lot, rowIndex) => (
              <Box
                key={rowIndex}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  minHeight: "4rem",
                  alignItems: "center",
                }}
              >
                <Box sx={{ minWidth: "7rem" }}>
                  <Typography align={"center"} variant="body1">
                    {lot.ranch.name} {lot.num}
                  </Typography>
                </Box>
                <Box sx={{ minWidth: "7rem" }}>
                  <Typography align={"center"} variant="body1">
                    {lot.numOfAcres} acres
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            flexWrap: "nowrap",
            gap: "0.5rem",
            // maxHeight: "90vh"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            {Array.from({ length: numYearsBack + 1 }, (value, index) => index)
              .sort((a, b) => b - a)
              .map((yearKey) => (
                <Box
                  key={yearKey}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      minWidth: `${
                        (cropRotationMeta[yearKey] === 0
                          ? 1
                          : cropRotationMeta[yearKey]) * cropWidth
                      }rem`,
                    }}
                  >
                    <Typography variant="h6" align="center">
                      Year {currentDate.getFullYear() - yearKey}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            {Array.from({ length: numYearsBack + 1 }, (value, index) => index)
              .sort((a, b) => b - a)
              .map((yearKey) => (
                <Box
                  key={yearKey}
                  sx={{
                    borderBottom: `1px solid ${grey[500]}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  {Array.from(
                    {
                      length:
                        cropRotationMeta[yearKey] === 0
                          ? 1
                          : cropRotationMeta[yearKey],
                    },
                    (value, index) => index,
                  ).map((cropRotationIndexByYear, indexByYear) => (
                    <React.Fragment  key={indexByYear}>
                      <Box
                        sx={{
                          minWidth: `${cropWidth*0.75}rem`,
                        }}
                      >
                        <Typography variant="h6" align="center">
                          Crop {cropRotationIndexByYear + 1}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          minWidth: `${cropWidth*0.25}rem`,
                        }}
                      >
                        <Typography variant="h6" align="center">
                          RP
                        </Typography>
                      </Box>
                    </React.Fragment>
                  ))}
                </Box>
              ))}
          </Box>
          {lots &&
            lots.map((lot, rowIndex) => (
              <LotCropRotation
                key={lot.id}
                lot={lot}
                numYearsBack={numYearsBack}
                alternate={rowIndex % 2 === 0}
                cropRotationMeta={cropRotationMeta}
              />
            ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Box sx={{ flexShrink: 1, display: "flex", flexDirection: "row" }}>
          <IconButton
            disabled={page <= 0}
            onClick={() => setPage((p) => p - 1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            disabled={page + 1 >= data?.meta["pages"]}
            onClick={() => setPage((p) => p + 1)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
