import React from 'react'
import { Typography } from '@mui/material'
import dayjs from "dayjs";
import { Spray } from '../types'

export default function SprayListItemInfo({ spray }: { spray: Spray }){
  return (
    <React.Fragment>
      <Typography variant="body1">
        {dayjs(spray.appliedOn).format("MM/DD/YYYY")}
        {/*{capitalize(spray.measuringMethod)} applied for{" "}*/}
        {/*{spray.numHours} hours.*/}
      </Typography>
      <Typography variant="subtitle2">
        {spray.reentryDay
          ? `Reentry in ${spray.reentryNum} ${spray.reentryNumType}`
          : "Reentry immediately"}
      </Typography>
      <Typography variant="subtitle2">
        {spray.harvestReentryDay
          ? `Harvest reentry in ${spray.harvestReentryNum} ${spray.harvestReentryNumType}`
          : "Harvest reentry immediately"}
      </Typography>
      <Typography variant="subtitle2">
        {spray.internalSpray
          ? "Internal spray"
          : `Sprayed by ${spray?.applicatorCompany?.name} company`}
      </Typography>
    </React.Fragment>
  )
}