import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { Dayjs } from 'dayjs'

import HarvestAPI from "../../api/Harvest.api";
import CommodityCreatableAutocomplete from "../../components/CommodityCreatableAutocomplete";
import ShipperCreatableAutocomplete from "../../components/ShipperCreatableAutocomplete";
import {
  Commodity,
  Lot,
  Shipper
} from "../../types";

export default function AddHarvestToLot({ lot }: { lot: Lot }) {
  const { enqueueSnackbar } = useSnackbar();

  const [commodity, setCommodity] = React.useState<Commodity | undefined>();
  const [estHarvestDate, setEstHarvestDate] = React.useState<Dayjs | null>(
    null,
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [projectedWetDate, setProjectedWetDate] = React.useState<Dayjs | null>(
    null,
  );
  const [shipper, setShipper] = React.useState<Shipper | undefined>();

  const { mutateAsync } = HarvestAPI.useCreateScheduled({
    lotableId: lot.id,
    lotableType: "Lot",
    estHarvestDate: estHarvestDate ? estHarvestDate.toDate() : undefined,
    projectedWetDate: projectedWetDate
      ? projectedWetDate.toDate()
      : projectedWetDate,
    commodityId: commodity?.id,
    shipperId: shipper?.id,
  });

  const handleCreate = async () => {
    try {
      if (isNil(projectedWetDate) && isNil(estHarvestDate)) {
        enqueueSnackbar(
          "Must select an estimated harvest date or a projected wet date",
          {
            variant: "error",
          },
        );
        return;
      }
      if (isNil(commodity)) {
        enqueueSnackbar("Must select a Commodity", {
          variant: "error",
        });
        return;
      }
      await mutateAsync();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "7rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton aria-label="create" onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatePicker
                label="Projected Wet Date"
                value={projectedWetDate}
                onChange={(value) => setProjectedWetDate(value)}
                sx={{ width: "100%" }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setProjectedWetDate(null),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Est Harvest Date"
                value={estHarvestDate}
                onChange={(value) => setEstHarvestDate(value)}
                sx={{ width: "100%" }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () => setEstHarvestDate(null),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CommodityCreatableAutocomplete
                setCurrentCommodity={(commodity: Commodity) =>
                  setCommodity(commodity)
                }
                commodity={commodity}
              />
            </Grid>
            <Grid item xs={12}>
              <ShipperCreatableAutocomplete
                setCurrentShipper={(shipper: Shipper) => setShipper(shipper)}
                shipper={shipper}
              />
            </Grid>
          </Grid>
          <Button onClick={handleCreate} variant="contained" fullWidth>
            Create
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
