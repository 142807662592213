import camelcaseKeys from "camelcase-keys";
import { snakeCase } from "lodash";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  Harvest,
  HarvestAasmState,
  HarvestFilterListQueryProps,
  HarvestInput,
  HarvestList,
  ListResponseData,
  MetaPagination,
  Sublot,
  SublotInput,
} from "../types";
import { extractDate, handleDates } from "../utils";
import client from "./client";
import {
  handleDelete,
  handleDetail,
  handleFileUpload,
  handleMutationError,
  handleQueryError,
  handleSave,
  handleBasicFetch
} from "./handler";
import queryClient from "./queryClient";

async function handleFilterList({
  baseUrl,
  commodities,
  dateType,
  endDate,
  harvestStateFilter,
  order,
  orderBy,
  page,
  query,
  ranch,
  shipper,
  startDate,
}: HarvestFilterListQueryProps): Promise<ListResponseData<HarvestList>> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (query) {
      searchParams.set("query", query);
    }
    if (order) {
      searchParams.set("order", order);
    }
    if (orderBy) {
      searchParams.set("order_by", snakeCase(orderBy.toString()));
    }
    if (dateType) {
      searchParams.set("date_type", dateType);
    }
    if (startDate) {
      searchParams.set("start_date", extractDate(startDate));
    }
    if (endDate) {
      searchParams.set("end_date", extractDate(endDate));
    }
    if (harvestStateFilter) {
      searchParams.set("harvest_state_filter", harvestStateFilter);
    }
    if (shipper) {
      searchParams.set("shipper", shipper?.id.toString());
    }
    if (ranch) {
      searchParams.set("ranch", ranch?.id.toString());
    }
    if (commodities) {
      searchParams.set(
        "commodities",
        commodities.map((commodity) => commodity.id.toString()).join(","),
      );
    }

    const response: { data: HarvestList[]; meta: MetaPagination } = await client
      .get(baseUrl, {
        searchParams,
      })
      .json();

    const parsedData = response.data
      ? response.data.map((obj) => handleDates(obj))
      : [];
    return camelcaseKeys(
      {
        data: parsedData || ([] as HarvestList[]),
        meta: response.meta as MetaPagination,
      },
      { deep: true },
    ) as ListResponseData<HarvestList>;
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useCreate: ({
    lotId,
    sublots,
  }: {
    lotId?: number;
    sublots?: SublotInput[];
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Harvest>({
      mutationFn: () =>
        handleSave({ baseUrl: "harvests", input: { lotId, sublots } }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
      onSuccess: async (data) => {
        enqueueSnackbar("Successfully created");
        await queryClient.refetchQueries(["harvests"]);
      },
    });
  },
  useUploadFile: ({ id, files }: { id?: number; files: any }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Harvest>({
      mutationFn: () =>
        handleFileUpload({
          url: `harvests/${id}/soil_analysis_files`,
          files,
          paramName: "files",
        }),
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useList: ({
    commodities,
    dateType,
    endDate,
    harvestStateFilter,
    order,
    orderBy,
    page,
    ranch,
    shipper,
    startDate,
  }: HarvestFilterListQueryProps) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<HarvestList>>({
      cacheTime: 0,
      queryKey: [
        "harvests",
        commodities,
        dateType,
        endDate,
        harvestStateFilter,
        order,
        orderBy,
        page,
        ranch,
        shipper,
        startDate,
      ],
      queryFn: () =>
        handleFilterList({
          baseUrl: "harvests",
          commodities,
          dateType,
          endDate,
          harvestStateFilter,
          order,
          orderBy,
          page,
          ranch,
          shipper,
          startDate,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useCreateScheduled: (harvestInput: HarvestInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Harvest>({
      mutationFn: () =>
        handleSave({ baseUrl: "harvests", input: { harvest: harvestInput } }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
      onSuccess: async (data) => {
        enqueueSnackbar("Successfully created", {
          variant: "success",
        });
        await queryClient.invalidateQueries(["harvests_scheduled"]);
        await queryClient.invalidateQueries(["lots_crop_rotation"]);
      },
    });
  },
  useDetail: ({ id }: { id?: string | number }) => {
    const navigate = useNavigate();
    return useQuery<Harvest>({
      cacheTime: 0,
      queryKey: ["harvest", id?.toString()],
      queryFn: () => handleDetail({ baseUrl: "harvests", id }),
      onError: handleQueryError(navigate),
    });
  },
  useUpdate: (harvestInput: HarvestInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Harvest>({
      mutationFn: () =>
        handleSave({ baseUrl: "harvests", input: harvestInput }),
      retry: 2,
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(["harvest", data.id.toString()]);
      },
    });
  },
  useUpdateAuto: () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
      mutationFn: (harvestInput: HarvestInput) =>
        handleSave({
          baseUrl: "harvests",
          input: harvestInput,
        }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
    });
  },
  useScheduledToHarvest: ({
    id,
    actualWetDate,
  }: {
    id: number;
    actualWetDate: Date | null;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Harvest>({
      mutationFn: () =>
        handleSave({
          baseUrl: `harvests/${id}/scheduled_to_harvested`,
          input: { actualWetDate },
        }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.refetchQueries(["harvest", data.id.toString()]);
        await queryClient.refetchQueries(["harvests_scheduled"]);
      },
    });
  },
  useTransitionState: ({
    harvestId,
    newState,
  }: {
    harvestId?: number;
    newState: HarvestAasmState;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Harvest>({
      mutationFn: () =>
        handleSave({
          baseUrl: `harvests/${harvestId}/transition`,
          input: { newState },
        }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.refetchQueries(["harvest", data.id.toString()]);
      },
    });
  },
  useSplitIntoSublots: ({
    harvestId,
    lotId,
    currentSublotNum,
    currentSublotNumOfAcres,
    sublots,
  }: {
    harvestId: number;
    lotId?: number;
    currentSublotNum?: string;
    currentSublotNumOfAcres?: number;
    sublots: SublotInput[];
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Sublot>({
      mutationFn: () =>
        handleSave({
          baseUrl: `harvests/${harvestId}/sublots`,
          input: {
            lotId,
            currentSublotNum,
            currentSublotNumOfAcres,
            sublots,
          },
        }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        await queryClient.refetchQueries(["harvest", data.id.toString()]);
      },
    });
  },
  useDelete: (id: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Sublot>({
      mutationFn: () =>
        handleDelete({
          baseUrl: "harvests",
          id,
        }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: () => {
        enqueueSnackbar("Deleted Harvest successfully", { variant: "success" });
      },
    });
  },
  useNitrogenUsed: (id: number) => {
    return useQuery<{ nitrogenUsed: number }>({
      queryKey: ["nitrogen_used", id?.toString()],
      queryFn: () => handleBasicFetch({ url: `harvests/${id}/nitrogen_used` }),
      // onError: handleQueryError(navigate),
    });
  }
};
export default methods;
