import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { isNil } from "lodash";
import React from "react";

import SprayAPI from "../api/Spray.api";
import {
  ApplicatorCompany,
  Spray,
  SprayMeasuringMethod,
  SprayReentryNumType,
  SprayableType,
} from "../types";
import ApplicatorCompanyCreatableAutocomplete from "./ApplicatorCompanyCreatableAutocomplete";
import MaterialsUsed from "./MaterialsUsed";
import MaterialsUsedList from "./MaterialsUsedList";
import SprayListItemInfo from './SprayListItemInfo'

type Props = {
  actualNumAcres: number;
  title: string;
  sprayableId: number;
  sprayableType: SprayableType;
};

export default function SprayList({
  actualNumAcres,
  title,
  sprayableId,
  sprayableType,
}: Props) {
  const [open, setOpen] = React.useState<boolean>(false);

  const { data: sprays } = SprayAPI.useList({
    sprayableId,
    sprayableType,
  });

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography>Sprays</Typography>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddCircleIcon />
        </Button>
      </Box>

      {sprays &&
        sprays.map((spray) => (
          <SprayItem
            key={spray.id}
            spray={spray}
            actualNumAcres={actualNumAcres}
          />
        ))}

      <SprayModal
        actualNumAcres={actualNumAcres}
        open={open}
        onClose={() => setOpen(false)}
        sprayableId={sprayableId}
        sprayableType={sprayableType}
      />
    </React.Fragment>
  );
}

type SprayItemProps = {
  spray: Spray;
  actualNumAcres: number;
};

function SprayItem({ spray, actualNumAcres }: SprayItemProps) {
  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const { mutateAsync } = SprayAPI.useDelete({
    id: spray.id,
  });

  const handleDelete = async () => {
    try {
      await mutateAsync();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        p: "1rem",
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
        border: `2px solid ${theme.palette.grey["300"]}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <SprayListItemInfo spray={spray} />
        </Box>

        <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>

      <Box>
        <MaterialsUsedList
          materialApplicableId={spray.id}
          materialApplicableType={"Spray"}
          phase={"spraying"}
        />
      </Box>

      <SprayModal
        actualNumAcres={actualNumAcres}
        open={openModal}
        onClose={() => setOpenModal(false)}
        spray={spray}
        sprayableId={spray.sprayableId}
        sprayableType={spray.sprayableType}
      />
    </Box>
  );
}

type SprayModalProps = {
  actualNumAcres: number;
  open: boolean;
  onClose: () => void;
  spray?: Spray;
  sprayableId: number;
  sprayableType: SprayableType;
};
function SprayModal({
  actualNumAcres,
  open,
  onClose,
  spray,
  sprayableId,
  sprayableType,
}: SprayModalProps) {
  const [sprayId, setSprayId] = React.useState<number | undefined>(spray?.id);
  const [appliedOn, setAppliedOn] = React.useState<Dayjs | null>(
    spray?.appliedOn ? dayjs(spray.appliedOn) : dayjs(new Date()),
  );
  const [harvestReentryDay, setHarvestReentryDay] = React.useState<boolean>(
    spray?.harvestReentryDay || false,
  );
  const [harvestReentryNum, setHarvestReentryNum] = React.useState<
    number | undefined
  >(spray?.harvestReentryNum || 0);
  const [harvestReentryNumType, setHarvestReentryNumType] =
    React.useState<SprayReentryNumType>(spray?.harvestReentryNumType || "days");

  const [measuringMethod, setMeasuringMethod] =
    React.useState<SprayMeasuringMethod>(spray?.measuringMethod || "rate");
  const [reentryDay, setReentryDay] = React.useState<boolean>(
    spray?.reentryDay || false,
  );
  const [reentryNum, setReentryNum] = React.useState<number | undefined>(
    spray?.reentryNum || 0,
  );
  const [reentryNumType, setReentryNumType] =
    React.useState<SprayReentryNumType>(spray?.reentryNumType || "days");
  const [internalSpray, setInternalSpray] = React.useState<boolean>(
    spray?.internalSpray || true,
  );
  const [applicatorCompany, setApplicatorCompany] = React.useState<
    ApplicatorCompany | undefined
  >(spray?.applicatorCompany);

  const { mutateAsync: saveMutateAsync } = SprayAPI.useSave({
    id: sprayId,
    sprayableId,
    sprayableType,
    appliedOn: appliedOn ? appliedOn.toDate() : appliedOn,
    applicatorCompanyId: applicatorCompany?.id,
    harvestReentryDay,
    harvestReentryNum,
    harvestReentryNumType,
    measuringMethod,
    reentryDay,
    reentryNum,
    reentryNumType,
    internalSpray,
  });

  const handleCreate = async () => {
    try {
      await saveMutateAsync();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const saveAndShowMaterials = async () => {
    try {
      const res = await saveMutateAsync();
      setSprayId(res.id);
    } catch (error) {
      console.error(error);
    }
  };

  const { mutateAsync: deleteMutateAsync } = SprayAPI.useDelete({
    id: sprayId,
  });

  const handleDelete = async () => {
    try {
      await deleteMutateAsync();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    if (isNil(spray)) {
      setSprayId(undefined);
      setAppliedOn(null);
      setMeasuringMethod("rate");
      setReentryDay(false);
      setReentryNum(0);
      setHarvestReentryDay(false);
      setHarvestReentryNum(0);
      setInternalSpray(true);
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-spray"
      aria-describedby="modal-spray-creation"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h6">Spray</Typography>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            listStyle: "none",
            gap: "1rem",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <DatePicker
              label="Applied On"
              value={appliedOn}
              onChange={(value) => setAppliedOn(value)}
              sx={{ width: "100%" }}
            />
          </Box>
          <FormControl fullWidth>
            <InputLabel id="measuring-method-select-label">
              Measuring Method
            </InputLabel>
            <Select
              labelId="measuring-method-select-label"
              id="measuring-method-select"
              value={measuringMethod}
              label="Measuring Method"
              onChange={(event) => {
                setMeasuringMethod(event.target.value as SprayMeasuringMethod);
              }}
            >
              <MenuItem value={"rate"}>Rate</MenuItem>
              <MenuItem value={"total_amount"}>Total Amount</MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "1rem",
              alignItems: "flex-start",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={reentryDay}
                    onChange={(event) => {
                      setReentryDay(event.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Rentry time?"
              />
            </FormGroup>
            {reentryDay && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <TextField
                  label="Number of days before reentry"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  value={reentryNum}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (isNil(e.target.value) || e.target.value.length === 0) {
                      setReentryNum(undefined);
                    } else {
                      const newVal = parseFloat(e.target.value);
                      if (newVal >= 0) setReentryNum(newVal);
                      if (newVal < 0) setReentryNum(0);
                    }
                  }}
                  fullWidth
                />
                <FormControl fullWidth>
                  <InputLabel id="reentry-num-type-select-label">
                    Reentry Number Type
                  </InputLabel>
                  <Select
                    labelId="reentry-num-type-select-label"
                    id="reentry-num-type-select"
                    value={reentryNumType}
                    label="Reentry Number Type"
                    onChange={(event) => {
                      setReentryNumType(
                        event.target.value as SprayReentryNumType,
                      );
                    }}
                  >
                    <MenuItem value={"days"}>Days</MenuItem>
                    <MenuItem value={"hours"}>Hours</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "1rem",
              alignItems: "flex-start",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={harvestReentryDay}
                    onChange={(event) => {
                      setHarvestReentryDay(event.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Harvest rentry time?"
              />
            </FormGroup>
            {harvestReentryDay && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <TextField
                  label="Number of days before reentry"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  value={harvestReentryNum}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (isNil(e.target.value) || e.target.value.length === 0) {
                      setHarvestReentryNum(undefined);
                    } else {
                      const newVal = parseFloat(e.target.value);
                      if (newVal >= 0) setHarvestReentryNum(newVal);
                      if (newVal < 0) setHarvestReentryNum(0);
                    }
                  }}
                  fullWidth
                />
                <FormControl fullWidth>
                  <InputLabel id="harvest-reentry-num-type-select-label">
                    Harvest Reentry Number Type
                  </InputLabel>
                  <Select
                    labelId="harvest-reentry-num-type-select-label"
                    id="harvest-reentry-num-type-select"
                    value={harvestReentryNumType}
                    label="Harvest Reentry Number Type"
                    onChange={(event) => {
                      setHarvestReentryNumType(
                        event.target.value as SprayReentryNumType,
                      );
                    }}
                  >
                    <MenuItem value={"days"}>Days</MenuItem>
                    <MenuItem value={"hours"}>Hours</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "1rem",
              alignItems: "flex-start",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={internalSpray}
                    onChange={(event) => {
                      setInternalSpray(event.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Internal Spray?"
              />
            </FormGroup>
            {!internalSpray && (
              <Box sx={{ display: "flex", flexGrow: 1, width: "100%" }}>
                <ApplicatorCompanyCreatableAutocomplete
                  applicatorCompany={applicatorCompany}
                  setCurrentApplicatorCompany={setApplicatorCompany}
                />
              </Box>
            )}
          </Box>
        </Box>

        {isNil(sprayId) ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "1rem",
            }}
          >
            <Typography variant="body1">
              Did you apply any materials?
            </Typography>
            <Button onClick={saveAndShowMaterials}>Yes</Button>
            <Button color="secondary" onClick={handleCreate}>
              No
            </Button>
          </Box>
        ) : (
          <MaterialsUsed
            numAcres={actualNumAcres}
            materialApplicableId={sprayId} // cultivation does not exist yet
            materialApplicableType={"Spray"}
            phase={"spraying"}
          />
        )}
        {!isNil(sprayId) && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <Button onClick={handleCreate} variant="contained" fullWidth>
              Save
            </Button>
            {!isNil(sprayId) && (
              <Button
                onClick={handleDelete}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Delete
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
